import React, {useEffect} from 'react'
import {BrowserRouter as Router, Redirect, Route, Switch, useLocation} from 'react-router-dom'

import {config} from "../config";
import {useDispatch, useSelector} from "react-redux";
import {getMyDetails} from "../redux/actions/authActions";

import AuthenticateHelper from '../helpers/authenticateHelper'

import Login from '../views/Login';
import ForgotPassword from "../views/ForgotPassword";
import ResetPassword from "../views/ResetPassword";
import Notifications from "../views/Notifications";
import Settings from "../views/Settings";

import UsersList from "../views/Users/UsersList";
import UserAddEdit from "../views/Users/UserAddEdit";
import UserView from "../views/Users/UserView";

import SystemsList from "../views/Systems/SystemsList";
import SystemAddEdit from "../views/Systems/SystemAddEdit";
import SystemView from "../views/Systems/SystemView";

import AreasList from "../views/Areas/AreasList";
import AreaAddEdit from "../views/Areas/AreaAddEdit";
import AreaView from "../views/Areas/AreaView";

import DevicesList from "../views/Devices/DevicesList";
import DeviceEdit from "../views/Devices/DeviceEdit";
import DeviceView from "../views/Devices/DeviceView";

import PlantAddEdit from "../views/Plants/PlantAddEdit";
import PlantsList from "../views/Plants/PlantsList";
import PlantView from "../views/Plants/PlantView";

import RuleEntitiesList from "../views/RuleEntities/RuleEntitiesList";

import AddEditViewRegisterEntry from "../views/OrchardEntries/AddEditViewRegisterEntry";

import DriversList from "../views/Drivers/DriversList";
import DriverAddEdit from "../views/Drivers/DriverAddEdit";

import RulesList from "../views/Rules/RulesList";
import RuleAddEdit from "../views/Rules/RuleAddEdit";

import PlantCategoriesList from "../views/PlantCategories/PlantCategoriesList";
import PlantCategoryAddEditView from "../views/PlantCategories/PlantCategoryAddEditView";

import Dashboard from "../views/Dashboard/Dashboard";
import GardenInfo from "../views/GardenInfo/GardenInfo";

import RegisterEntryCategoryList from "../views/OrchardEntries/RegisterEntryCategoryList";
import RegisterEntryList from "../views/OrchardEntries/RegisterEntryList";
import IrrigationManagement from "../views/IrrigationManagement/IrrigationManagement";


const Routes = (props) => {

    const dispatch = useDispatch();
    const role = useSelector((state) => state.authData?.userDetails?.role)
    const available = useSelector((el) => el.systemData.availableList)

    const authorization = AuthenticateHelper();
    const TARGET_CODE = config.TARGET_CODE;

    useEffect(()=>{
        if(!role && authorization){
            dispatch(getMyDetails())
        }
    },[])

    const routes = [
        { route: '/users', roles: ['admin'], component: <UsersList /> },
        { route: '/user/view/:userId', roles: ['admin'], component: <UserView /> },
        { route: '/user/edit/:userId', roles: ['admin'], component: <UserAddEdit role={useSelector((el)=> el.authData)} isEdit={true} /> },
        { route: '/user/add', roles: ['admin'], component: <UserAddEdit isEdit={false} /> },
        { route: '/systems', roles: ['admin', 'supervisor'], component: <SystemsList /> },
        { route: '/system/view/:systemId', roles: ['admin', 'supervisor'], component: <SystemView /> },
        { route: '/system/edit/:systemId', roles: ['admin'], component: <SystemAddEdit isEdit={true} /> },
        { route: '/system/add', roles: ['admin'], component: <SystemAddEdit isEdit={false} /> },
        { route: '/system/:systemId/areas/', remove: 'VLM', roles: ['admin', 'supervisor'], component: <AreasList /> },
        { route: '/system/:systemId/area/view/:areaId', remove: 'VLM', roles: ['admin', 'supervisor'], component: <AreaView /> },
        { route: '/system/:systemId/area/edit/:areaId', remove: 'VLM', roles: ['admin', 'supervisor'], component: <AreaAddEdit isEdit={true} /> },
        { route: '/system/:systemId/area/add', remove: 'VLM', roles: ['admin', 'supervisor'], component: <AreaAddEdit isEdit={false} /> },
        { route: '/system/:systemId/devices/', roles: ['admin', 'supervisor', 'customer', 'guest'], component: <DevicesList /> },
        { route: '/system/:systemId/device/view/:deviceId', roles: ['admin', 'supervisor', 'customer', 'guest'], component: <DeviceView /> },
        { route: '/system/:systemId/device/edit/:deviceId', roles: ['admin', 'supervisor'], component: <DeviceEdit isEdit={true} /> },
        { route: '/system/:systemId/plants/', remove: 'VLM', roles: ['admin', 'supervisor', 'customer', 'guest'], component: <PlantsList /> },
        { route: '/system/:systemId/plant/view/:plantId', remove: 'VLM', roles: ['admin', 'supervisor', 'customer', 'guest'], component: <PlantView /> },
        { route: '/system/:systemId/plant/edit/:plantId', remove: 'VLM', roles: ['admin', 'supervisor'], component: <PlantAddEdit isEdit={true} /> },
        { route: '/system/:systemId/plant/add', remove: 'VLM', roles: ['admin', 'supervisor'], component: <PlantAddEdit isEdit={false} /> },
        { route: '/system/:systemId/register-entries/', remove: 'VLM', roles: ['admin', 'supervisor'], component: <RegisterEntryCategoryList /> },
        { route: '/system/:systemId/register-entries/category/:categoryId', remove: 'VLM', roles: ['admin', 'supervisor'], component: <RegisterEntryList /> },
        { route: '/system/:systemId/register-entries/category/:categoryId/entry/view/:entryId', remove: 'VLM', roles: ['admin', 'supervisor'], component: <AddEditViewRegisterEntry isDisabled={true}/> },
        { route: '/system/:systemId/register-entries/category/:categoryId/entry/edit/:entryId', remove: 'VLM', roles: ['admin', 'supervisor'], component: <AddEditViewRegisterEntry isEdit={true} /> },
        { route: '/system/:systemId/register-entries/category/:categoryId/entry/add', remove: 'VLM', roles: ['admin', 'supervisor'], component: <AddEditViewRegisterEntry isEdit={false} /> },
        { route: '/system/:systemId/rule-entities/', remove: 'VLM', roles: ['admin', 'supervisor', 'customer', 'guest'], component: <RuleEntitiesList /> },
        { route: '/system/:systemId/drivers/', roles: ['admin'], component: <DriversList /> },
        { route: '/system/:systemId/driver/view/:driverId', roles: ['admin'], component: <DriverAddEdit isDisabled={true} /> },
        { route: '/system/:systemId/driver/edit/:driverId', roles: ['admin'], component: <DriverAddEdit isEdit={true} /> },
        { route: '/system/:systemId/driver/add/driver-config/:driverConfigId', roles: ['admin'], component: <DriverAddEdit isEdit={false} /> },
        { route: '/system/:systemId/rules/', roles: ['admin', 'supervisor'], component: <RulesList /> },
        { route: '/system/:systemId/rule/view/:ruleId', roles: ['admin', 'supervisor'], component: <RuleAddEdit isDisabled={true} /> },
        { route: '/system/:systemId/rule/edit/:ruleId', roles: ['admin', 'supervisor'], component: <RuleAddEdit isEdit={true} /> },
        { route: '/system/:systemId/rule/add/rule-config/:ruleConfigId', roles: ['admin', 'supervisor'], component: <RuleAddEdit isEdit={false} /> },
        { route: '/settings', roles: ['admin', 'supervisor', 'customer', 'guest'], component: <Settings /> },
        { route: '/notifications', roles: ['admin', 'supervisor', 'customer', 'guest'], component: <Notifications /> },
        { route: '/system/:systemId/dashboard', roles: ['admin', 'supervisor', 'customer', 'guest'], component: <Dashboard /> },
        { route: '/plant-categories', remove: 'VLM', roles: ['admin', 'globalsupervisor'], component: <PlantCategoriesList /> },
        { route: '/plant-categories/add', remove: 'VLM', roles: ['admin', 'globalsupervisor'], component: <PlantCategoryAddEditView isEdit={false} /> },
        { route: '/plant-categories/edit/:plantCategoryId', remove: 'VLM', roles: ['admin', 'globalsupervisor'], component: <PlantCategoryAddEditView isEdit={true} /> },
        { route: '/plant-categories/view/:plantCategoryId', remove: 'VLM', roles: ['admin', 'globalsupervisor'], component: <PlantCategoryAddEditView isEdit={false} isView={true} /> },
        { route: '/system/:systemId/garden-info', remove: 'VLM', roles: ['admin', 'supervisor', 'customer', 'guest'], component: <GardenInfo /> },
        { route: '/system/:systemId/irrigation-management', remove: 'VLM', roles: ['admin', 'supervisor'], component: <IrrigationManagement /> },
    ]

    if (!authorization) {
        return (
            <Router>
                <Switch>
                    <Route path='/login'><Login/></Route>
                    <Route path='/forgot-password'><ForgotPassword/></Route>
                    <Route path='/reset-password'><ResetPassword/></Route>
                    <Redirect to="/login"/>
                </Switch>
            </Router>
        )
    } else {
        if (TARGET_CODE === 'VLM_LOCAL') {
            return (
                <Router>
                    <Switch>
                        <Route exact path='/system/:systemId/devices/'><DevicesList /></Route>
                        <Route exact path='/system/:systemId/device/view/:deviceId'><DeviceView /></Route>

                        <Route exact path='/notifications'><Notifications /></Route>
                        <Route exact path='/system/:systemId/dashboard'><Dashboard /></Route>

                        <Redirect to={`/system/${available[0]._id}/dashboard`}/>

                    </Switch>
                </Router>
            )
        } else {
            return (
                <Router>
                    <Switch>

                        {routes.filter((el)=> !el.remove || el.remove !== TARGET_CODE).filter((el)=> el.roles.some((el2)=> role.includes(el2))).map((item, id)=>{
                            return(
                                <Route exact key={id} path={item.route}>
                                    {item.component}
                                </Route>
                            )
                        })}

                        <Redirect to={(role === 'admin' || role.includes('supervisor')) ? "/systems" : `/system/${available[0]._id}/dashboard` }/>

                    </Switch>
                </Router>
            )
        }
    }
}

export default Routes;